import type { EarlyAccessDetails } from '@/types/early-access/early-access-response.type';
import type { TranslatedContent } from '@/types/terms/terms-response.type';

export const earlyAccessContentsMapper = (data: EarlyAccessDetails): TranslatedContent[] => {
  const contents: Record<string, string> = JSON.parse(JSON.stringify(data.contents));

  const translatedContents = [];
  for (const [key, value] of Object.entries(contents)) {
    const lang = key;
    const content = value;

    translatedContents.push({ lang, content });
  }

  return translatedContents;
};

export const earlyAccessContentsFormMapper = (
  data: Omit<EarlyAccessDetails, 'contents'>,
  translatedContents: TranslatedContent[]
): EarlyAccessDetails => {
  const { groupId, productNo, projectId } = data;

  const contents = translatedContents.reduce(
    (acc: Record<string, string>, cur: TranslatedContent) => {
      acc[cur.lang.toLowerCase()] = cur.content;
      return acc;
    },
    {}
  );

  return {
    contents,
    groupId,
    productNo,
    projectId
  };
};
