<template>
  <div class="flex gap-24">
    <div class="flex-1 max-w-[63.2rem]">
      <st-box>
        <st-form-title
          :key="referenceLanguage"
          :formTitle="$t('studio.prj_prod.this_prod.home_early_access_msg')"
          languageUse
          alignLeftLanguageUse
          :referenceLanguage="$t(`${referenceLanguage}`)"
          :slotClass="'ml-auto'"
          @onLanguageClick="onSettingMultiLanguage"
        >
          <div class="shrink-0 flex items-center gap-4">
            <s-button
              size="xs"
              variant="outline"
              icon="ic-v2-media-av-fullscreen-fill"
              :isDisabled="isCodeView"
              @click="handleFullScreen"
            >
              {{ $t('studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr') }}
            </s-button>
            <s-tooltip
              arrow
              :content="$t('studio.prj_prod.this_prod.home_early_access_tooltip_hover_msg')"
              :duration="0"
              :offset="[8, 4]"
              useFlip
              flipOnUpdate
              placement="bottom-end"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="2500"
            >
              <template #target>
                <s-icon
                  size="xl"
                  icon="ic-v2-state-info-circle-line"
                  class="h-16 text-on-surface-elevation-4"
                />
              </template>
            </s-tooltip>
          </div>
        </st-form-title>
        <!-- 에디터 -->
        <text-editor
          v-show="!isFullScreen"
          v-model="content"
          name="content"
          class="mt-8 h-[52rem]"
          :toolbarButtons="TOOLBAR_BUTTONS_FROALA"
          :projectId="projectId"
          :config="editorConfig"
          :disabled="!isProductWritable"
          :wordCountLimit="WORD_COUNT_LIMIT"
          :charCounterMax="WORD_COUNT_LIMIT"
          :rules="{
            required: {
              value: true,
              message: $t('studio.prj_prod.this_prod.home_early_access_prompt_required_n')
            },
            max_char_count: {
              length: WORD_COUNT_LIMIT,
              charCount: wordCount,
              message: $t('studio.common.def_key.exceed', {
                length: WORD_COUNT_LIMIT
              })
            }
          }"
          @wordCount="wordCount = $event"
          @onClickBtnHtmlCodeBlock="isCodeView = $event"
        />
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="outline" size="lg" class="!min-w-160" @click="preview">
          {{ $t('studio.prj_prod.this_prod.home_early_access_preview_btn') }}
        </s-button>
        <s-button
          variant="primary"
          size="lg"
          class="!min-w-160"
          @click="onSubmit"
        >
          {{ $t('studio.prj_prod.this_prod.home_early_access_save_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.prj_prod.this_prod.home_early_access_guide1')"
        numberValue="01"
      >
        {{ $t('studio.prj_prod.this_prod.home_early_access_guide1_1') }}
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="$t('studio.prj_prod.this_prod.home_early_access_guide2')"
        numberValue="02"
        class="mt-24"
      >
        {{ $t('studio.prj_prod.this_prod.home_early_access_guide2_1') }}
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta, useProduct } from '@/.nuxt/imports';
import { createEarlyAccess, fetchEarlyAccess, updateEarlyAccess } from '@/apis/early-access.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import FullScreen from '@/components/early-access/fullscreen.vue';
import Preview from '@/components/early-access/preview.vue';
import MultiLanguageDialog from '@/components/product/dialog/multilanguage-setting-dialog.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { DEFAULT_VALUE_CONTENT_EARLY_ACCESS } from '@/constants/early-access.const';
import { STATUS_CODE } from '@/constants/error.const';
import { TOOLBAR_BUTTONS_FROALA } from '@/constants/froala.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { PRODUCT_TITLE } from '@/constants/page.const';
import { ROLE_ID } from '@/constants/permission.const';
import { PRODUCT_FEAT_NAME, PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { MULTILANGUAGE_DIALOG_NAME } from '@/constants/terms.const';
import { LanguageCode } from '@/enums/language-code.enum';
import { RuleNames } from '@/enums/validation.enum';
import {
  earlyAccessContentsFormMapper,
  earlyAccessContentsMapper
} from '@/mappers/early-access.mapper';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse, Language } from '@/types/common/common.type';
import type { EarlyAccessRequest } from '@/types/early-access/early-access-request.type';
import type { EarlyAccessDetails } from '@/types/early-access/early-access-response.type';
import type { TranslatedContent } from '@/types/terms/terms-response.type';
import { showConfirmLeaveDialog } from '@/utils/common.util';
import { getHtmlTextByCompare } from '@/utils/string.util';
import { generateErrorMsg } from '@/utils/validation.util';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  roleAuthorityId: ROLE_ID.PRODUCT_EARLY_ACCESS_TEXT_SETTING,
  pageTitle: PRODUCT_TITLE.EARLY_ACCESS,
  productFeat: PRODUCT_FEAT_NAME.EARLY_ACCESS,
  accessibleProductTypes: [
    PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
    PRODUCT_TYPE_DETAIL.UTILITY_DLC,
    PRODUCT_TYPE_DETAIL.GAME_DLC,
    PRODUCT_TYPE_DETAIL.GAME_BASIC
  ]
});

const WORD_COUNT_LIMIT = 7000;

const { checkProductPermission } = useApp();

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const { t } = useI18n();

const route = useRoute();

const productStore = useProductStore();
const appStore = useAppStore();
const userStore = useUserStore();

const { languages } = storeToRefs(appStore);
const { isProductWritable } = storeToRefs(productStore);
const { selectedGroupInfo } = storeToRefs(userStore);

const projectId = route.params.projectId as string;

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  height: '52rem'
};

const { handleSubmit, setValues, values } = useForm({
  initialValues: {
    content: ''
  }
});

const formContentValue = useFieldValue<string>('content');
const setContentError = useSetFieldError('content');

const defaultValueContent = ref<string>(
  DEFAULT_VALUE_CONTENT_EARLY_ACCESS[selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE]
);
const mode = ref<string>(MODE.CREATE);
const earlyAccessNo = ref<string>('');
const content = ref<string>('');
const isCodeView = ref<boolean>(false);
const isFullScreen = ref<boolean>(false);

const wordCount = ref<number>(0);

const isContentUpdated = ref<boolean>(false);

const locale = computed(() => selectedGroupInfo.value?.languageCd ?? LanguageCode.Ko);

const referenceLanguage = computed(() => {
  if (locale.value.toLowerCase() === 'ko') {
    return 'studio.ai_translation.base_lang_ko';
  }
  return `studio.ai_translation.base_lang_${locale.value.toLowerCase().replace('-', '_')}`;
});

const translatedContent = ref<TranslatedContent[]>([]);

const onSettingMultiLanguage = async () => {
  await hasContent();

  if (values.content === '') {
    return;
  }

  const sourceLangName = Object.keys(LanguageCode).find(
    (key: string) => LanguageCode[key as keyof typeof LanguageCode] === locale.value
  );

  const translations = await showDialog<TranslatedContent[]>({
    component: shallowRef(MultiLanguageDialog),
    props: {
      // content: isHTMLUsingParser(content.value) ? content.value : formContentValue.value,
      content: getHtmlTextByCompare(content.value, formContentValue.value),
      sourceLang: {
        name: sourceLangName,
        value: locale.value
      },
      translatedContent: translatedContent.value.filter(
        (t: TranslatedContent) => t.lang !== locale.value
      ),
      projectId,
      page: MULTILANGUAGE_DIALOG_NAME.EARLY_ACCESS_FORM,
      disabled: !isProductWritable
    }
  });

  if (translations) {
    translatedContent.value = translations;
    isContentUpdated.value = false;
  }
};

const hasContent = async () => {
  if (values.content !== '') {
    return;
  }

  await showAlert({
    content: t('studio.early_access.popup_case_lang_setting_input_required')
  });
};

const preview = () => {
  hasContent();

  showDialog({
    component: shallowRef(Preview),
    props: {
      content: content.value
    }
  });
};

const handleFullScreen = async () => {
  isFullScreen.value = true;
  const { valueHtml, valueRaw } = await showDialog<{ valueRaw: string; valueHtml: string }>({
    component: shallowRef(FullScreen),
    props: {
      content: getHtmlTextByCompare(content.value, formContentValue.value),
      wordCountLimit: WORD_COUNT_LIMIT,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA,
      isDisabled: !isProductWritable
    }
  });
  isFullScreen.value = false;
  content.value = getHtmlTextByCompare(valueHtml, valueRaw);
  setValues({ content: getHtmlTextByCompare(valueHtml, valueRaw) });
};

const formatData = () => {
  translatedContent.value.push({ lang: locale.value, content: content.value });

  const data: Omit<EarlyAccessDetails, 'contents'> = {
    groupId: route.params.groupId as string,
    projectId: route.params.projectId as string,
    productNo: route.params.productId as string
  };

  const request = earlyAccessContentsFormMapper(data, translatedContent.value);

  if (wordCount.value > WORD_COUNT_LIMIT) {
    setContentError(generateErrorMsg('studio.common.def_key.exceed', RuleNames.MAX_LENGTH));

    return;
  }

  return request;
};

const isMultiLanguagePopupShown = () => {
  const translated = translatedContent.value.filter(
    (t: TranslatedContent) => t.lang !== locale.value
  );

  return translated.some((t: TranslatedContent) => t.content === '');
};

const onSubmit = handleSubmit(async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  const request = formatData();

  if (!request) {
    return;
  }

  // If content is not entered
  if (content.value === defaultValueContent.value || content.value === '') {
    await showAlert({
      content: t('studio.early_access.no_change_msg'),
      cancelClasses: 'stds-button stds-button-primary'
    });

    return;
  }

  // If Multi language is not entered
  if (isMultiLanguagePopupShown()) {
    await showAlert({
      content: t('studio.common.popup_case_ai_not_entered')
    });

    onSettingMultiLanguage();
    return;
  }

  // If content is updated but Multi language is not updated
  if (isContentUpdated.value) {
    const res = await showAlert({
      content: t('studio.common.popup_case_ai_modified'),
      cancelClasses: 'stds-button stds-button-primary',
      isCancelButtonVisible: true,
      cancelVariant: 'outline',
      confirmLabel: t('studio.common.popup_case_save_btn')
    });

    if (!res) {
      return;
    }

    submit(request);

    return;
  }

  submit(request);
});

const submit = async (request: EarlyAccessDetails) => {
  try {
    if (mode.value === MODE.CREATE) {
      await createEarlyAccess(request);
    } else {
      await updateEarlyAccess(request);
    }

    showCompleteSaveDialog();
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    // TODO: translate error
    await showAlert({
      severity: 'danger',
      content: errorCode.toString()
    });
  }
};

const setDefaultTranslatedContent = () => {
  translatedContent.value = languages.value.map((lang: Language) => ({
    lang: lang.value.toLowerCase(),
    content: ''
  }));
};

const init = async () => {
  try {
    const params: EarlyAccessRequest = {
      productNo: Number(route.params.productId)
    };

    const res = await fetchEarlyAccess(params);
    if (res) {
      mode.value = MODE.EDIT;
      earlyAccessNo.value = res.earlyAccessNo ?? '';
      translatedContent.value = earlyAccessContentsMapper(res);

      languages.value.forEach((lang: Language) => {
        if (!translatedContent.value.find((term: TranslatedContent) => term.lang.toLowerCase() === lang.value.toLowerCase())) {
          translatedContent.value.push({ lang: lang.value.toLowerCase(), content: '' });
        }
      });

      const localeTerm =
        translatedContent.value.find(
          (term: TranslatedContent) => term.lang.toUpperCase() === locale.value.toUpperCase()
        )?.content ?? '';
      content.value = localeTerm;
      setValues({ content: localeTerm });
    } else {
      content.value = defaultValueContent.value;
      setValues({ content: defaultValueContent.value });
      setDefaultTranslatedContent();
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    if (errorCode === STATUS_CODE.EARLY_ACCESS_NOT_FOUND) {
      content.value = defaultValueContent.value;
      setValues({ content: defaultValueContent.value });
      setDefaultTranslatedContent();

      return;
    }

    await showAlert({
      content: errorCode.toString()
    });
  }
};

init();

watch(
  () => content.value,
  () => {
    isContentUpdated.value = true;
  }
);

onBeforeRouteLeave(async () => {
  return await showConfirmLeaveDialog();
});
</script>
